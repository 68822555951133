@font-face {
  font-family: FyranSans;
  src:
    url('/static/tv4/fyran-sans-regular.woff2') format('woff2'),
    url('/static/tv4/fyran-sans-regular.woff') format('woff');
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}

@font-face {
  font-family: FyranSans;
  src:
    url('/static/tv4/fyran-sans-medium.woff2') format('woff2'),
    url('/static/tv4/fyran-sans-medium.woff') format('woff');
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}

@font-face {
  font-family: 'text-security-disc';
  src: url('/static/text-security-disc.woff2') format('woff2');
}
